import React, { useRef } from "react";
import { Link } from "gatsby";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Tabs from "../../components/tabs/Tabs";
import TabLink from "../../components/tabs/TabLink";
import TabContent from "../../components/tabs/TabContent";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";

import Layout from "../../components/mschool/layout";
import SEO from "../../components/seo";
import SchedulePopup from "../../components/schedulepopup";
import ProInstructors from "../../components/mschool/proinstructors";
import ComingSoon from "../../components/mschool/comingsoon";
import useWindowSize from "../../hooks/useWindowSize";
import TopBanner from "../../components/mschool/topbanner";
import settings from "../../../settings";
import ThermalCaliforniaBanner from "../../components/mschool/thermalcaliforniabanner";
import VideoBackground from '../../components/videoBackground';


// images
const banner =
	`${settings.IMAGES_BASE_URL}/v2/images/m4-gt4/m4-gt4-banner.jpg`;
const bannerMobile =
	`${settings.IMAGES_BASE_URL}/v2/images/m4-gt4/m4-gt4-banner.jpg`;
const beast =
	`${settings.IMAGES_BASE_URL}/v2/images/m4-gt4/bmw-m4-meet-beast.jpg`;

const mdriver =
	`${settings.IMAGES_BASE_URL}/v2/images/class-thumbnails/m-drivers-class.jpg`;
const onedaymschool =
	`${settings.IMAGES_BASE_URL}/v2/images/class-thumbnails/one-day-m-class.jpg`;
const twodaymschool =
	`${settings.IMAGES_BASE_URL}/v2/images/class-thumbnails/two-day-m-class.jpg`;
const sccaschool =
	`${settings.IMAGES_BASE_URL}/v2/images/class-thumbnails/race-license-class.jpg`;
const advancedmschool =
	`${settings.IMAGES_BASE_URL}/v2/images/class-thumbnails/advanced-m-school-banner%201.png`;
const indyfullday =
	`${settings.IMAGES_BASE_URL}/v2/images/indy_images/full-day-indy-box.jpg`;

const slideone =
	`${settings.IMAGES_BASE_URL}/v2/images/m4-gt4/dress-success.jpg`;
const slidetwo =
	`${settings.IMAGES_BASE_URL}/v2/images/m4-gt4/4_before_4.jpg`;
const slidethree =
	`${settings.IMAGES_BASE_URL}/v2/images/m4-gt4/lead-follow.jpg`;
const slidefour =
	`${settings.IMAGES_BASE_URL}/v2/images/m4-gt4/pit_lane.jpg`;

const videoSrc = `${settings.IMAGES_BASE_URL}/v2/videos/M4_GT4.mp4`;

const Agenda = () => {
	return (
		<SchedulePopup
			btnText="View School Agenda"
			title="M4 GT4 EXPERIENCE"
			subtitle="Sample schedule subject to change."
		>
			<div className="schedule__item">
				<p className="schedule__item__time">8:30 am</p>
				<p className="schedule__item__event">Arrival</p>
			</div>
			<div className="schedule__item">
				<p className="schedule__item__time">8:30 am - 9:15 am</p>
				<p className="schedule__item__event">
					Class discussion, proper seating
				</p>
			</div>

			<div className="schedule__item">
				<p className="schedule__item__time">9:15 am - 12:15 pm</p>
				<p className="schedule__item__event">Drive time </p>
			</div>

			<div className="schedule__item">
				<p className="schedule__item__time">12:15 pm - 1:00 pm</p>
				<p className="schedule__item__event">Lunch</p>
			</div>
			<div className="schedule__item">
				<p className="schedule__item__time">1:15 pm - 4:00 pm</p>
				<p className="schedule__item__event">Drive time</p>
			</div>
			<div className="schedule__item">
				<p className="schedule__item__time">4:00 pm - 4:15 pm</p>
				<p className="schedule__item__event">Closing</p>
			</div>
		</SchedulePopup>
	);
};

const M4GT4Page = () => {
	const scrollToView = (ref) => {
		ref.current.scrollIntoView({
			behavior: "smooth",
		});
	};
	const { width } = useWindowSize();
	const isMobile = width < 1200;
	const isDesktop = width >= 1200;

	const bookingOptions = useRef(null);
	return (
		<Layout>
			<SEO
				title="BMW M4 GT4 Experience | BMW Performance Driving School"
				description="Drive a BMW racecar firsthand with the BMW M4 GT4 Experience at the BMW Performance Driving School in California."
				keywords="bmw m4, bmw m4 performance, bmw m4 gt4"
			/>
			<div className="mfourpage">
      {isDesktop && (
        <VideoBackground
          url={videoSrc}
          topLeftChildren={
            <div className="header">
            <div>
              <h1>
                BMW M4 GT4 Experience
              </h1>
              <div className="sub-header">
                DRIVE A BMW RACECAR
              </div>
              <br />
            </div>
            <div>
              <button className="btn btn-blue" onClick={() => {
                scrollToView(bookingOptions);
              }}>
              See booking options
              </button>
              <br />
              <div className="agenda-btn">
                {<Agenda />}
              </div>
              </div>
            </div>
          }
        />
      )}
      {isMobile && (
        <TopBanner
          image={banner}
          mobileImage={bannerMobile}
          header="BMW M4 GT4 Experience"
          subheader="DRIVE A BMW RACECAR"
          text="Hood vent. Canards. Front splitter. Big rear wing. This is the BMW M4 GT4, and it’s ready for you to take it around our Thermal track as fast as you can. Be prepared, this M4 grips harder, accelerates faster and brakes shorter than anything we’ve had before."
          buttonText="See booking options"
          onClickFirstButton={() => {scrollToView(bookingOptions);
          }}
          agendaPopup={<Agenda />}
          arrow
        />
      )}
				<section className="learn">
					<h2>WHAT YOU’LL LEARN</h2>
					{isMobile && (
						<Accordion atomic={true}>
							<AccordionItem title="Dress For Success">
								<LazyLoadImage
									effect="blur"
									src={slideone}
									alt="M4 GT4 - Dress for Success"
								/>
								<div className="accordion-content">
									<h3>Get Fitted</h3>
									<p>
										If you want to be a pro race car driver, it’s best to start
										by looking the part. Get fitted for your uniform and helmet,
										complete with BMW M tri-color stripes, before stepping into
										the GT4.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Get a Feel">
								<LazyLoadImage
									effect="blur"
									src={slidetwo}
									alt="M4 GT4 - Get a feel"
								/>
								<div className="accordion-content">
									<h3>The 4 before the 4</h3>
									<p>
										To get you up to speed in both a figurative and literal
										sense, you’ll start out in street M4s and M2s as you guide
										them around our track. With at least 405 horses on tap,
										you’ll be well prepared for an afternoon race car session
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Lead-Follow">
								<LazyLoadImage
									effect="blur"
									src={slidethree}
									alt="M4 GT4 - Lead-follow"
								/>
								<div className="accordion-content">
									<h3>Follow the leader</h3>
									<p>
										Just like our other lead-follow exercises, except you’re in
										a race car. So is the instructor. Expect speeds to be even
										higher as you follow the right line and build up confidence.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Pit Lane">
								<LazyLoadImage
									effect="blur"
									src={slidefour}
									alt="M4 GT4 - Pit Lane"
								/>
								<div className="accordion-content">
									<h3>Grab and go</h3>
									<p>
										The M4 GT4 has extremely grippy racing tires and a powerful
										M TwinPower Turbo engine, so after each session you’ll get
										your tires checked, along with a refuel when needed. Get a
										feel of what it’s like for the pros.
									</p>
								</div>
							</AccordionItem>
						</Accordion>
					)}
					<div className="container">
						{isDesktop && (
							<Tabs>
								<div className="learn__images">
									<TabContent for="dress-success" uniqueKey="1">
										<img effect="blur" src={slideone} alt="M4 GT4 - Dress for Success" />
									</TabContent>
									<TabContent for="getafeel" uniqueKey="2">
										<img effect="blur" src={slidetwo} alt="M4 GT4 - Get a feel" />
									</TabContent>
									<TabContent for="lead-follow" uniqueKey="3">
										<img src={slidethree} alt="M4 GT4 - Lead-follow" />
									</TabContent>
									<TabContent for="pit-lane" uniqueKey="4">
										<img src={slidefour} alt="M4 GT4 - Pit Lane" />
									</TabContent>
								</div>
								<ul className="learn__menu" role="tablist" aria-owns="dress-success getafeel lead-follow pit-lane" aria-busy="true">
									<li role="presentation">
										<TabLink  role="tab" id="dress-success"  to="dress-success" uniqueKey="5">Dress For Success</TabLink>
									</li>
									<li role="presentation">
										<TabLink  role="tab" id="getafeel" to="getafeel" uniqueKey="6">Get a Feel</TabLink>
									</li>
									<li role="presentation">
										<TabLink  role="tab" id="lead-follow" to="lead-follow" uniqueKey="7">Lead-Follow</TabLink>
									</li>
									<li role="presentation">
										<TabLink  role="tab" id="pit-lane" to="pit-lane" uniqueKey="8">Pit Lane</TabLink>
									</li>
								</ul>
								<div className="learn__content">
									<TabContent for="dress-success" uniqueKey="9">
										<h3>Get Fitted</h3>
										<p>
											If you want to be a pro race car driver, it’s best to
											start by looking the part. Get fitted for your uniform and
											helmet, complete with BMW M tri-color stripes, before
											stepping into the GT4.
										</p>
									</TabContent>
									<TabContent for="getafeel" uniqueKey="10">
										<h3>The 4 before the 4</h3>
										<p>
											To get you up to speed in both a figurative and literal
											sense, you’ll start out in street M4s and M2s as you guide
											them around our track. With at least 405 horses on tap,
											you’ll be well prepared for an afternoon race car session
										</p>
									</TabContent>
									<TabContent for="lead-follow" uniqueKey="11">
										<h3>Follow the leader</h3>
										<p>
											Just like our other lead-follow exercises, except you’re
											in a race car. So is the instructor. Expect speeds to be
											even higher as you follow the right line and build up
											confidence.
										</p>
									</TabContent>
									<TabContent for="pit-lane" uniqueKey="12">
										<h3>Grab and go</h3>
										<p>
											The M4 GT4 has extremely grippy racing tires and a
											powerful M TwinPower Turbo engine, so after each session
											you’ll get your tires checked, along with a refuel when
											needed. Get a feel of what it’s like for the pros.
										</p>
									</TabContent>
								</div>
							</Tabs>
						)}
					</div>
				</section>
				<ThermalCaliforniaBanner/>
				<section className="beast">
					<div className="striped-background">
						<div className="blue-stripe" />
						<div className="darkblue-stripe" />
						<div className="red-stripe" />
					</div>
					<div className="row">
						<div className="col">
							<LazyLoadImage effect="blur" src={beast} alt="BMW M4 GT4" />
						</div>
						<div className="col">
							<div className="beast__content">
								<h2>Meet the BMW M4 GT4</h2>
								<p>
                  Hood vent. Canards. Front splitter. Big rear wing. This is the BMW M4 GT4, 
                  and it's ready for you to take it around our Thermal track as fast as you can. 
                  Be prepared, this lighter M4 has turned into a genuine racecar, and it grips harder, 
                  accelerates faster and brakes shorter than anything we've had before. 
                  You'll feel it as soon you climb in.
								</p>
							</div>
						</div>
					</div>
				</section>
				<ProInstructors image={`${settings.IMAGES_BASE_URL}/v2/images/m4-gt4/bmw-pro-instructors-banner-m4-gt4.jpg`} />
				<div ref={bookingOptions}>
					<ComingSoon />
				</div>
				<section id="end-page" className="other-schools">
					<div className="container">
						<h2>YOU MAY ALSO LIKE</h2>
					</div>
					<div className="other-schools__content row">
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={onedaymschool}
								alt="One Day M School"
							/>
							<h3>One Day M School</h3>
							<p>The basics of performance are anything but basic.</p>
							<Link to="/mschool/onedaymschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={twodaymschool}
								alt="Two-Day M School"
							/>
							<h3>Two-Day M School</h3>
							<p>Focus on the details of true M Performance</p>
							<Link to="/mschool/twodaymschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={advancedmschool}
								alt="Advanced M School"
							/>
							<h3>Advanced M School</h3>
							<p>Become a pro.</p>
							<Link to="/mschool/advancedmschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>

						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={mdriver}
								alt="M Driver’s Program"
							/>
							<h3>M Driver’s Program</h3>
							<p>Take yourself to the limit.</p>
							<Link to="/mschool/mdriver">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>

						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={sccaschool}
								alt="Race License School"
							/>
							<h3>Race License School</h3>
							<p>The faint of heart need not apply.</p>
							<Link to="/mschool/racelicenseschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={indyfullday}
								alt="Full-Day M Experience at Indy"
							/>
							<h3>Full-Day M Experience at Indy</h3>
							<p>Full-on thrills on a hallowed track.</p>
							<Link to="/indy-fullday">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
					</div>
				</section>
			</div>
		</Layout>
	);
};

export default M4GT4Page;
